* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'IBM Plex Sans';
}

textarea {
  resize: none;
}

::selection {
  background: var(--sapphire3);
  color: white;
}

input[type='date'] {
  margin-top: -3px;
}

::-webkit-calendar-picker-indicator {
  cursor: pointer;
  filter: opacity(0);
  margin-left: 0px;
  position: absolute;
  width: 40px;
}

::-webkit-datetime-edit-year-field {
  display: none;
}

::placeholder {
  opacity: 0.3;
}

.loader {
  background: radial-gradient(
    circle 6px at 25px 175px,
    var(--sapphire3) 0%,
    var(--sapphire3) 89%,
    transparent 90%
  );
  animation: 2s linear 1s infinite rotate;
  transform: rotate(90deg);
}

@keyframes rotate {
  0% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(450deg);
  }
}
